import { default as SuccessIcon } from '@mui/icons-material/CheckCircleRounded';
import { PasswordlessLogin } from '@verifime/cognito-passwordless-auth';
import { useRouter } from 'next/router';
import { Landing } from '@verifime/components';

export default function LoginPage() {
  const router = useRouter();

  const signInRedirect = () => {
    const from = router.query['from'] as string;
    // Redirect to previous url if there it is
    if (from) {
      router.push(decodeURIComponent(from));
      return;
    }

    router.push('/admin');
  };

  return (
    <Landing title="Admin Portal" description="*For internal use only">
      <PasswordlessLogin signInCallback={signInRedirect}>
        <SuccessIcon color="success" />
      </PasswordlessLogin>
    </Landing>
  );
}
